<script>
import each from 'lodash/each';
import get from 'lodash/get';
import some from 'lodash/some';
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { watch } from 'vue';
import { STORE_REQUEST_STATUS } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { GenericModalComponent } from '@/components';
import { ACTIONS } from './const';
import { CARSHARING_PERMISSIONS } from '../../../const/permissions';
import { scopes as INTERACTION_SCOPES } from './store/InteractionsModule';

export default {
  name: 'DetailsInteractionsView',
  components: {
    GenericModalComponent,
  },
  data() {
    return {
      action: '',
      vehicleUuid: null,
      isModalVisible: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.carsharing.bookings.details, {
      booking: state => state.data,
    }),
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.information, {
      isConnectedCarPlatform: state => !!get(state, 'general.data.is_connected_car_platform'),
    }),
    modalHeaderTitle() {
      switch (this.action) {
        case ACTIONS.MASTER_MENU:
          return 'Are you sure you want to open the master menu?';
        case ACTIONS.IMMOBILISE:
          return 'Are you sure you want to immobilise this vehicle?';
        default:
          return '';
      }
    },
    ...mapState(DOMAINS_MODEL.carsharing.vehicle.details.interactions, {
      mobilizeStatus: state => state.mobilize.STATUS,
      immobilizeStatus: state => state.immobilize.STATUS,
      doorsLockStatus: state => state.doorsLock.STATUS,
      doorsUnlockStatus: state => state.doorsUnlock.STATUS,
      menuOpenStatus: state => state.menuOpen.STATUS,
      menuCloseStatus: state => state.menuClose.STATUS,
      virtualKeysStatus: state => state.virtualKeys.STATUS,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'hasPermissions',
    ]),
  },
  async created() {
    this.ACTIONS = ACTIONS;
    this.CARSHARING_PERMISSIONS = CARSHARING_PERMISSIONS;
    this.vehicleUuid = get(this, '$route.params.vehicle_uuid') || get(this, 'booking.vehicle.uuid');
    this.actionRoute = get(this, '$route.meta.action', false);

    if (this.actionRoute === ACTIONS.INVALIDATE_KEYS) {
      await this.getVehicleDetails(this.vehicleUuid);
    }

    watch(
      () => [
        this.mobilizeStatus.LOADED,
        this.immobilizeStatus.LOADED,
        this.doorsLockStatus.LOADED,
        this.doorsUnlockStatus.LOADED,
        this.menuOpenStatus.LOADED,
        this.menuCloseStatus.LOADED,
        this.virtualKeysStatus.LOADED,
      ],
      newLoadedStatus => {
        const isSomeStatusLoaded = some(newLoadedStatus, status => status);

        if (isSomeStatusLoaded) {
          this.$notify({
            message: 'Request <span class="emobg-font-weight-semibold">sent</span>',
            textAction: '',
          });
          each(INTERACTION_SCOPES, scope => this.setStatus({
            scope,
            value: STORE_REQUEST_STATUS.idle,
          }));
        }
      },
    );
  },
  methods: {
    get,
    ...mapMutations(DOMAINS_MODEL.carsharing.vehicle.details.interactions, [
      'setStatus',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.interactions, [
      'getLockVehicle',
      'getUnlockVehicle',
      'getOpenMasterMenu',
      'getCloseMasterMenu',
      'getImmobilizeVehicle',
      'getMobilizeVehicle',
      'patchInvalidateVirtualKey',
    ]),
    ...mapActions(DOMAINS_MODEL.carsharing.vehicle.details.information, [
      'getVehicleDetails',
    ]),
    openMasterMenu() {
      this.action = ACTIONS.MASTER_MENU;
      this.isModalVisible = true;
    },
    immobilise() {
      this.action = ACTIONS.IMMOBILISE;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    confirmAction() {
      switch (this.action) {
        case ACTIONS.MASTER_MENU:
          this.getOpenMasterMenu(this.vehicleUuid);
          break;
        case ACTIONS.IMMOBILISE:
          this.getImmobilizeVehicle(this.vehicleUuid);
          break;
        default:
          break;
      }
      this.closeModal();
    },
  },
};
</script>

<template>
  <div class="DetailsInteractionsView">
    <div class="d-flex row">
      <div
        v-if="actionRoute === ACTIONS.INVALIDATE_KEYS && isConnectedCarPlatform"
        class="d-flex col-xl-6 mb-4"
      >
        <ui-card class="d-flex flex-column w-100">
          <div class="d-flex justify-content-between flex-grow-1 flex-column p-3">
            <div class="d-flex flex-column">
              <h2 class="mb-4 emobg-font-weight-bold">
                Invalidate virtual keys
              </h2>
              <p class="mb-2 emobg-font-weight-bold emobg-color-black">
                Invalidate virtual keys for this booking
              </p>
            </div>

            <div class="d-flex justify-content-end">
              <ui-button
                :loading="virtualKeysStatus.LOADING"
                :disabled="virtualKeysStatus.LOADING"
                data-test-id="invalidate_virtual-keys"
                class="height--initial wmin-initial py-2 mr-2"
                @clickbutton="patchInvalidateVirtualKey(get(booking,'uuid'))"
              >
                <ui-icon
                  :icon="ICONS.keyfob"
                  :size="ICONS_SIZES.small"
                  class="pr-2"
                />
                Invalidate virtual keys
              </ui-button>
            </div>
          </div>
        </ui-card>
      </div>
      <div
        v-if="hasPermissions(CARSHARING_PERMISSIONS.viewCsVehicleInteractionsLock)"
        class="d-flex col-xl-6 mb-4"
      >
        <ui-card class="d-flex flex-column w-100">
          <div class="d-flex justify-content-between flex-grow-1 flex-column p-3">
            <div class="d-flex flex-column">
              <h2 class="mb-4 emobg-font-weight-bold">
                Lock and unlock the vehicle doors
              </h2>
              <p class="mb-2 emobg-font-weight-bold emobg-color-black">
                These buttons will lock and unlock the vehicle doors.
              </p>
              <p class="mb-4">
                This function could be used when a customer has forgotten something in the vehicle once his booking is finalised.
              </p>
            </div>

            <div class="d-flex justify-content-end">
              <ui-button
                :loading="doorsUnlockStatus.LOADING"
                :disabled="doorsLockStatus.LOADING"
                class="height--initial wmin-initial py-2 mr-2"
                @clickbutton="getUnlockVehicle(vehicleUuid)"
              >
                <ui-icon
                  :icon="ICONS.unlock"
                  :size="ICONS_SIZES.small"
                  class="pr-2"
                />
                Unlock doors
              </ui-button>
              <ui-button
                :loading="doorsLockStatus.LOADING"
                :disabled="doorsUnlockStatus.LOADING"
                class="height--initial wmin-initial py-2"
                @clickbutton="getLockVehicle(vehicleUuid)"
              >
                <ui-icon
                  :icon="ICONS.lock"
                  :size="ICONS_SIZES.small"
                  class="pr-2"
                />
                Lock doors
              </ui-button>
            </div>
          </div>
        </ui-card>
      </div>

      <div
        v-if="hasPermissions(CARSHARING_PERMISSIONS.viewCsVehicleInteractionsLockMaster)"
        class="d-flex col-xl-6 mb-4"
      >
        <ui-card class="d-flex flex-column w-100">
          <div class="d-flex justify-content-between flex-grow-1 flex-column p-3">
            <div class="d-flex flex-column">
              <h2 class="mb-4 emobg-font-weight-bold">
                Lock and unlock the vehicle doors and master menu
              </h2>
              <p class="mb-2 emobg-font-weight-bold emobg-color-black">
                These buttons will lock and unlock the vehicle doors and at the same time open and close the
                master menu in the vehicle terminal.
              </p>
              <p class="mb-4">
                These should be used only if an internal team member is in front of the vehicle and never to
                open the doors to a customer.
              </p>
            </div>

            <div class="d-flex justify-content-end">
              <ui-button
                :loading="menuOpenStatus.LOADING"
                :disabled="menuCloseStatus.LOADING"
                :color="COLORS.danger"
                class="height--initial wmin-initial py-2  mr-2"
                @clickbutton="openMasterMenu"
              >
                <ui-icon
                  :icon="ICONS.unlock"
                  :size="ICONS_SIZES.small"
                  class="pr-2"
                />
                Unlock & Open master menu
              </ui-button>
              <ui-button
                :loading="menuCloseStatus.LOADING"
                :disabled="menuOpenStatus.LOADING"
                class="height--initial wmin-initial py-2"
                @clickbutton="getCloseMasterMenu(vehicleUuid)"
              >
                <ui-icon
                  :icon="ICONS.lock"
                  :size="ICONS_SIZES.small"
                  class="pr-2"
                />
                Lock and Close master menu
              </ui-button>
            </div>
          </div>
        </ui-card>
      </div>

      <div
        v-if="hasPermissions(CARSHARING_PERMISSIONS.viewCsVehicleInteractionsMobilise)"
        class="d-flex col-xl-6 mb-4"
      >
        <ui-card class="d-flex flex-column w-100">
          <div class="d-flex justify-content-between flex-grow-1 flex-column p-3">
            <div class="d-flex flex-column">
              <h2 class="mb-4 emobg-font-weight-bold">
                Mobilise and immobilise the vehicle
              </h2>
              <p class="mb-2 emobg-font-weight-bold emobg-color-black">
                These buttons will either immobilise or mobilise the vehicle.
              </p>
              <p class="mb-4">
                When you immobilise a vehicle the engine cannot be started again once turned off.
                For the user to be able to turn on the vehicle again it will need to be mobilised.
              </p>
            </div>

            <div class="mt-4 d-flex justify-content-end">
              <ui-button
                :loading="immobilizeStatus.LOADING"
                :disabled="mobilizeStatus.LOADING"
                :color="COLORS.danger"
                class="height--initial wmin-initial py-2 mr-2"
                @clickbutton="immobilise()"
              >
                Immobilise
              </ui-button>
              <ui-button
                :loading="mobilizeStatus.LOADING"
                :disabled="immobilizeStatus.LOADING"
                class="height--initial wmin-initial py-2"
                @clickbutton="getMobilizeVehicle(vehicleUuid)"
              >
                Mobilise
              </ui-button>
            </div>
          </div>
        </ui-card>
      </div>
    </div>

    <GenericModalComponent
      v-if="isModalVisible"
      :header="null"
      backdrop-dismiss
      @close-modal="closeModal"
      @modal-closed="closeModal"
      v-on="$listeners"
    >
      <template slot="body">
        <div class="emobg-font-large emobg-font-weight-semibold emobg-font-line-height-large pb-3 emobg-color-ink">
          {{ modalHeaderTitle }}
        </div>

        <div
          v-if="action === ACTIONS.MASTER_MENU"
          class="p-0"
        >
          <p class="pb-4">
            Note that this action will only work if the card reader is active.
            Also, be aware that this action will terminate any started booking.
          </p>
          <p class="pb-1">
            PIN code: 7037
          </p>
        </div>
        <div v-if="action === ACTIONS.IMMOBILISE">
          <p class="pb-1">
            If you immobilise the vehicle, the driver won't be able to turn on the engine again once turned off.
          </p>
        </div>
      </template>
      <template slot="footer">
        <div class="d-flex align-content-center justify-content-end w-100">
          <ui-button
            :color="GRAYSCALE.inkLight"
            :face="FACES.text"
            class="height--initial wmin-initial py-2 mr-2"
            @clickbutton="closeModal"
          >
            Cancel
          </ui-button>

          <ui-button
            :color="COLORS.danger"
            class="height--initial wmin-initial py-2"
            @clickbutton="confirmAction"
          >
            {{ action === ACTIONS.IMMOBILISE ? 'Immobilise' : 'Confirm' }}
          </ui-button>
        </div>
      </template>
    </GenericModalComponent>
  </div>
</template>
